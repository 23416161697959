import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import logo from "../assets/images/humainly-logo.png"
import "../assets/sass/main.scss"

class Header extends Component {
  state = {
    open: false
  }

  handleClick = (e) => {
    e.preventDefault()
    this.setState(state => ({ open: !state.open }))
  }

  render() {
    return (
      <>
        <Helmet>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
                integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
                crossOrigin="anonymous"/>
          <link href='https://fonts.googleapis.com/css?family=Quattrocento+Sans' rel='stylesheet' type='text/css'/>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
                media="screen" title="no title"/>
          <script defer src="https://use.fontawesome.com/releases/v5.8.1/js/all.js"
                  integrity="sha384-g5uSoOSBd7KkhAMlnQILrecXvzst9TdC09/VM+pjDTCM+1il8RHz5fKANTFFb+gQ"
                  crossOrigin="anonymous"></script>
          ]);
        </Helmet>
        <header className="dark">
          <div className="container">
            <div className="row">
              <div className="nav-anchor"><a href="/" onClick={this.handleClick}><i className="fa fa-bars"></i></a></div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                <div className="logo">
                  <a href="/"><img src={logo} alt="Humainly"/></a>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                <ul className={`head-nav ${this.state.open ? '' : 'mobile'}`}>
                  <li><Link to='/about/'>About us</Link></li>
                  <li><Link to='/contact/'>Contact Sales</Link></li>
                  <li><a href="https://app.humainly.com">Login</a></li>
                  <li><Link to="/recruit/" className="button button--red button--rounded">Apply as a Worker</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
