import { Link } from "gatsby"
import React from "react"
import logoSmall from "../assets/images/humainly-small.png"

const Footer = () => (
  <>
    <footer className="pt-4 my-md-4 pt-md-5 border-top">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img className="mb-2" src={logoSmall} alt="Humainly" width="44" height="44"/>
              <small className="d-block mb-3 text-muted">Accurate image annotation.</small>
              <small className="d-block mb-3 text-muted">Impactful people’s agency through fair micro-jobs.</small>
              <ul className="nav text-small">
                <li><a className="xx nav-item" target="_blank" href="https://www.linkedin.com/company/humainly" rel="noopener noreferrer"><i style={{color: "#6c757d" }} className="fab fa-linkedin-in"></i></a></li>
                <li><a className="xx nav-item" target="_blank" href="https://www.facebook.com/humainly/" rel="noopener noreferrer"><i style={{color: "#6c757d" }} className="fab fa-facebook-f"></i></a></li>
                <li><a className="xx nav-item" target="_blank" href="https://twitter.com/Humainly_ai" rel="noopener noreferrer"><i style={{color: "#6c757d" }} className="fab fa-twitter"></i></a></li>
              </ul>
          </div>

          <div className="col-md-3"></div>

          <div className="col-md-3">
            <h5>Organisation</h5>
            <ul className="list-unstyled text-small">
              <li><Link to='/about/' className="text-muted" >About us</Link></li>
              {/*<li><a class="text-muted" href="/blog.html">Blog</a></li>*/}
            </ul>
          </div>

          <div className="col-md-3">
            <h5>Contact</h5>
            <ul className="list-unstyled text-small">
              {/*<li className="text-muted">Kempische Steenweg 311, po 1.02<br/>3500 Hasselt<br/>Belgium<br/><br/></li>*/}
              <li><a className="text-muted" href="mailto:hello@humainly.com">hello@humainly.com</a></li>
              <li className="pt-2"><span className="text-muted">Humainly is a product of <a href="https://webmonks.vision">WebMonks vzw</a> and based in Belgium.</span></li>
            </ul>
          </div>
        </div>

        <div className="footnote">
          <p className="small-text">Copyright &copy; {new Date().getFullYear()} Humainly - A non-profit from Belgium</p>
          <ul className="small-text">
            <li><Link to="/privacy/">Privacy Policy</Link></li>
            <li><Link to="/terms/">Terms of use</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  </>
)

export default Footer
